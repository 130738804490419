import React from 'react';

import SEO from './components/seo';
import { Link } from 'gatsby';

export default () => {
    return (
        <header className="masthead" style={{height:'100vh'}}>
            <SEO title="Page Not Found"/>
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-10 align-self-end">
                <h1 className="text-uppercase text-white font-weight-bold">404</h1>
                    <p className="text-white font-weight-bolder">Page Not Found :(</p>
                <hr className="divider my-4"/>
              </div>
              <div className="col-lg-8 align-self-baseline">
                <p className="text-white-75 font-weight-light mb-5">Whoooops!!</p>
                <Link className="btn btn-primary btn-xl" to="/" >Go Home</Link>
              </div>
            </div>
          </div>
        </header>
    )
}